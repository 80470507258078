



























































import mixins from 'vue-typed-mixins'
import Section from '@/builder/sections/mixins/Section'
import DefaultButton from '@/builder/sections/section/components/DefaultButton.vue'
import GoogleMapsController from '@/calendesk/controllers/GoogleMapsController'
import * as uuid from 'uuid'

export default mixins(Section).extend({
  name: 'FlexibleMap1',
  components: { DefaultButton },
  data () {
    return {
      map: null as any,
      maker: null as any,
      dynamicMapClassName: uuid()
    }
  },
  created () {
    this.$nextTick(() => {
      setTimeout(this.initMap, Math.floor(Math.random() * 5000))
    })
  },
  watch: {
    data () {
      this.$nextTick(() => {
        this.initMap()
      })
    }
  },
  methods: {
    async initMap () {
      const location = { lat: Number(this.data.configuration.wb_latitude__number__), lng: Number(this.data.configuration.wb_longitude__number__) }
      const maps = document.getElementsByClassName(this.dynamicMapClassName)

      if (maps && maps.length > 0) {
        const google = await GoogleMapsController.getInstance().getGoogleMaps()
        const element = maps[0]

        if (google && element) {
          this.map = new google.maps.Map(element as HTMLElement, {
            center: location,
            zoom: parseInt(this.data.configuration.wb_zoom__number__)
          })

          if (!this.data.configuration.wb_map_maker_hide__checkbox__) {
            this.maker = new google.maps.Marker({
              position: location,
              map: this.map
            })
          }
        }
      }
    }
  }
})
